const offlineRemote = () => {
    const fallback = id => {
        throw Error(
            `Failed to load remote module| Looks like [${id}] is offline| Please try again later|`,
        );
    };

    const getModule = (pg, from) => {
        if (from === 'build') {
            return () => ({
                __esModule: true,
                default: pg,
            });
        }
        return {
            default: pg,
        };
    };

    return {
        name: 'offline-remote-plugin',
        errorLoadRemote({ id, error, from }) {
            return getModule(fallback(id, error), from);
        },
    };
};

export default offlineRemote;

import toExpression from './expression-resolver';
const remoteUrlResolverPluginProd = () => ({
    name: 'remote-url-resolver-plugin',
    // Set the remote URL at runtime
    beforeInit(args) {
        envSwitcher();
        const { userOptions } = args;

        userOptions.remotes.forEach(remote => {
            const urlExpression = toExpression(remote.entry);
            const [urlPath, filename] = urlExpression.split(' + ');
            remote.entry = `${window.env[urlPath]}${filename ? filename : ''}`;
        });

        return args;
    },
});

const envSwitcher = () => {
    const ENVIRONMENT = window?.env?.ENVIRONMENT ? window.env.ENVIRONMENT : '';
    switch (ENVIRONMENT) {
        case 'auproduction':
            require('../../env/auproduction');
            break;
        case 'euproduction':
            require('../../env/euproduction');
            break;
        case 'staging':
            require('../../env/staging');
            break;
        case 'ukproduction':
            require('../../env/ukproduction');
            break;
        case 'usproduction':
            require('../../env/usproduction');
            break;
        default:
            require('../../env/staging');
            break;
    }
};
export default remoteUrlResolverPluginProd;
